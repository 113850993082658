<template>
	<el-scrollbar class="teacher-page">
		<div class="t-page">
			<div class="t-page-title">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ name: 'teacherOrder' }">{{$t('teacher.breadcrumb.orderManage')}}</el-breadcrumb-item>
					<el-breadcrumb-item>{{$t('teacher.breadcrumb.details')}}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="t-page-wrap">
				<el-form label-width="100px">
					<div class="t-page-form-title">{{$t('teacher.order.paneTitle1')}}</div>
					<div class="t-page-form-main">
						<el-form-item :label="$t('teacher.formItem.orderSn')+':'">{{orderInfo.order_sn}}</el-form-item>
						<el-form-item :label="$t('teacher.formItem.orderStatus')+':'">
						{{ orderInfo.status | statusFilter($t('teacher.filters.orderStatus')) }}
						</el-form-item>
						<el-form-item :label="$t('teacher.formItem.orderAmount')+':'">￥{{orderInfo.order_amount}}</el-form-item>
						<el-form-item :label="$t('teacher.formItem.freight')+':'">￥{{orderInfo.order_extm.shipping_fee}}</el-form-item>
					</div>
					<div class="t-page-form-main">
						<el-form-item :label="$t('teacher.formItem.buyers')+':'">{{orderInfo.buyer_name}}</el-form-item>
						<el-form-item :label="$t('teacher.formItem.shop')+':'">{{orderInfo.seller_name}}</el-form-item>
						<el-form-item :label="$t('teacher.formItem.payMode')+':'">{{orderInfo.payment_name}}</el-form-item>
						<el-form-item :label="$t('teacher.formItem.orderTime')+':'">{{orderInfo.add_time | parseTime()}}</el-form-item>
						<el-form-item :label="$t('teacher.formItem.payTime')+':'">{{orderInfo.pay_time  | parseTime()}}</el-form-item>
						<el-form-item :label="$t('teacher.formItem.finishedTime')+':'">{{orderInfo.finished_time  | parseTime()}}</el-form-item>
					</div>
					<div class="t-page-form-title">{{$t('teacher.order.paneTitle2')}}</div>
					<div class="t-page-form-main">
						<el-form-item :label="$t('teacher.formItem.receiverName')+':'">{{orderInfo.order_extm.consignee}}</el-form-item>
						<el-form-item :label="$t('teacher.formItem.phone')+':'">{{orderInfo.order_extm.phone_mob?orderInfo.order_extm.phone_mob:orderInfo.order_extm.phone_tel}}</el-form-item>
						<el-form-item :label="$t('teacher.formItem.fullAddress')+':'">{{orderInfo.order_extm.order_id>0?orderInfo.order_extm.region_name+orderInfo.order_extm.address:'-'}}</el-form-item>
						<el-form-item :label="$t('teacher.formItem.shipmentNo')+':'">{{orderInfo.logistic_code}}</el-form-item>
					</div>
					<div class="t-page-form-title">{{$t('teacher.order.paneTitle3')}}</div>
					<div class="t-page-form-main">
						<el-form-item :label="$t('teacher.formItem.consignor')+':'">{{orderInfo.shipping_address.shipaddr_name}}</el-form-item>
						<el-form-item :label="$t('teacher.formItem.phone')+':'">{{orderInfo.shipping_address.phone_mob?orderInfo.shipping_address.phone_mob:orderInfo.shipping_address.phone_tel}}</el-form-item>
						<el-form-item :label="$t('teacher.formItem.shipmentPlace')+':'">{{orderInfo.shipping_address.shipaddr_id>0?orderInfo.shipping_address.region_name+orderInfo.shipping_address.address:''}}</el-form-item>
						<el-form-item :label="$t('teacher.formItem.returnPlace')+':'">{{orderInfo.receiving_address.shipaddr_id>0?orderInfo.receiving_address.region_name+orderInfo.receiving_address.address:''}}</el-form-item>
					</div>
					<div class="t-page-form-title">{{$t('teacher.order.paneTitle4')}}</div>
					<div class="t-page-form-main">
						<a class="goods-item" v-for="(item,index) in orderInfo.order_goods" :key="index" :href="$itemBasePath+'/m/item/good/'+item.goods_id" target="_blank">
							<img :src="item.goods_image?item.goods_image:$imageErrorGoods" alt="" @error="imageLoadError" class="pic">
							<div class="item-main">
								<div class="name">{{item.goods_name}}</div>
								<div class="price">￥{{item.price}}</div>
							</div>
							<div class="item-num">x{{item.quantity}}</div>
						</a>
					</div>
				</el-form>
			</div>
		</div>
	</el-scrollbar>
</template>

<script>
	import { fetchOrder } from '@/api/teacher';
	export default {
		filters: {
			statusFilter(status, option) {
				return option[status]
			}
		},
		data() {
			return {
				orderInfo: {
					order_extm: {},
					order_goods: [],
					receiving_address: {},
					shipping_address: {},
				},
			}
		},
		created() {
			this.getOrderInfo();
		},
		methods: {
			imageLoadError(e) {
				e.target.src = this.$imageErrorGoods;
				e.target.style.objectFit = 'fill';
			},
			getOrderInfo(){
				fetchOrder({order_id: this.$route.params.id}).then(response => {
					this.orderInfo = response.data;
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.t-page {
		padding: 20px;
		.t-page-title {
			padding-bottom: 20px;
			.title {
				line-height: 20px;
				font-size: 16px;
				font-weight: bold;
			}
		}
		.t-page-wrap {
			.t-page-form-title {
				font-weight: bold;
				line-height: 20px;
				padding: 10px;
				border-top: 1px solid #E5E5E5;
			}
			.t-page-form-main {
				padding: 10px 0;
				border-top: 1px dashed #E5E5E5;
				.el-form-item {
					width: 50%;
					display: inline-block;
					vertical-align: top;
					margin-bottom: 0;
				}
				.goods-item {
					width: 50%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 12px;
					.pic {
						width: 80px;
						height: 80px;
					}
					.item-main {
						flex: 1;
						width: 0;
						min-height: 80px;
						padding: 0 20px;
						.name {
							font-size: 14px;
							line-height: 20px;
							margin-bottom: 10px;
						}
						.price {
							color: red;
							font-size: 14px;
							line-height: 20px;
						}
					}
					.item-num {
						font-size: 14px;
					}
				}
			}
		}
	}
</style>
